import * as React from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Stack,
  DrawerCloseButton,
  DrawerHeader,
  Button,
  Text,
  Box,
  Link,
  Flex,
  DrawerFooter,
  DrawerBody,
  Image,
} from '@chakra-ui/core';
import { FaPhone } from 'react-icons/fa';
import logo from '../images/home-logo-white.png';

export interface MobileSidebarProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

export const MobileNavbar = ({ isOpen, setIsOpen }: MobileSidebarProps) => {
  return (
    <Drawer
      size="full"
      isOpen={isOpen}
      placement="right"
      onClose={() => setIsOpen(false)}
    >
      <DrawerOverlay />

      <DrawerContent bg="drawer">
        <DrawerCloseButton />
        <DrawerHeader>
          <Image height="60px" src={logo} alt="Mobile navbar logo" />
        </DrawerHeader>
        <DrawerBody justifyContent="center">
          <Stack align="center" spacing="30px">
            <Link href="tel:0411-067-700">
              <Flex align="center">
                <Box
                  mr="8px"
                  transform="scaleX(-1)"
                  as={FaPhone}
                  color="white"
                  fontWeight="bold"
                />

                <Text color="white">0411 067 700</Text>
              </Flex>
            </Link>
            <Link onClick={() => setIsOpen(false)} href="#about">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                About
              </Button>
            </Link>
            <Link onClick={() => setIsOpen(false)} href="#removal">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Removal
              </Button>
            </Link>
            <Link onClick={() => setIsOpen(false)} href="#assessment">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Assessment
              </Button>
            </Link>
            <Link onClick={() => setIsOpen(false)} href="#credentials">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Credentials
              </Button>
            </Link>
            <Link onClick={() => setIsOpen(false)} href="#location">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Location
              </Button>
            </Link>

            <Link
              _hover={{
                textDecoration: 'none',
              }}
              rel="noopener noreferrer"
              href="https://app.jescoasbestos.com.au"
              target="_blank"
            >
              <Button
                variant="outline"
                size="sm"
                variantColor="blue"
                bg="transparent"
              >
                Member portal
              </Button>
            </Link>
          </Stack>
        </DrawerBody>
        <DrawerFooter justifyContent="center">
          <Stack align="center">
            <Link
              rel="noopener noreferrer"
              href="https://maps.google.com/maps?q=17 Capital Terrace Queanbeyan NSW, 2620"
              fontWeight={300}
              color="#CEE7F2"
              target="_blank"
            >
              17 Capital Terrace Queanbeyan NSW, 2620
            </Link>
            <Text fontWeight={300} color="#CEE7F2">
              ABN: 95607762201
            </Text>
            <Text fontWeight={300} color="#CEE7F2">
              License No: ARA00003
            </Text>
            <Text fontWeight={300} color="#CEE7F2">
              License Class: Class A
            </Text>
            <Link
              color="#CEE7F2"
              fontWeight={400}
              href="mailto: admin@jescoasbestos.com.au"
            >
              admin@jescoasbestos.com.au
            </Link>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
