import React from 'react';
import {
  Button,
  Link,
  Box,
  IconButton,
  Flex,
  Image,
  Text,
  FlexProps,
} from '@chakra-ui/core';
import { FaBars, FaPhone } from 'react-icons/fa';
import logo from '../images/home-logo-white.png';

export interface NavbarProps extends FlexProps {
  isSidebarOpen: boolean;
  onToggleSidebar(): void;
}

export const Navbar = ({
  isSidebarOpen,
  onToggleSidebar,
  ...props
}: NavbarProps) => {
  return (
    <Flex
      as="nav"
      justify="center"
      p="1rem"
      width="100%"
      position="relative"
      zIndex={6}
      paddingX={['1rem', '1rem', '1rem', '3rem']}
      paddingY={['1rem', '1rem', '1rem', '2rem']}
      bg="#101112"
      color="#101112"
      {...props}
    >
      <Flex
        width="100%"
        align="center"
        justify="space-between"
        maxWidth="1200px"
      >
        <Button
          variant="solid"
          height="auto"
          variantColor="white"
          onClick={() => window.scrollTo(0, 0)}
        >
          <Image height="60px" src={logo} alt="navbar logo" />
        </Button>

        <Flex
          display={{
            xs: 'flex',
            sm: 'flex',
            md: 'none',
          }}
        >
          <IconButton
            aria-label="hamburger-menu"
            icon={FaBars}
            variant="ghost"
            variantColor="blue"
            color="white"
            fontSize="26px"
            onClick={onToggleSidebar}
          />
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="flex-end"
          display={{
            xs: 'none',
            sm: 'none',
            md: 'flex',
          }}
        >
          <Flex mb="10px">
            <Link href="tel:0411-067-700">
              <Flex align="center">
                <Box
                  mr="8px"
                  transform="scaleX(-1)"
                  as={FaPhone}
                  color="white"
                  fontWeight="bold"
                />

                <Text as="h1" color="white">
                  0411 067 700
                </Text>
              </Flex>
            </Link>
          </Flex>
          <Flex>
            <Link href="#about" mr="20px">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                About
              </Button>
            </Link>
            <Link href="#removal" mr="20px">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Removal
              </Button>
            </Link>
            <Link href="#assessment" mr="20px">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Assessment
              </Button>
            </Link>
            <Link href="#credentials" mr="20px">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Credentials
              </Button>
            </Link>
            <Link href="#location" mr="20px">
              <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
                Location
              </Button>
            </Link>

            <Link
              _hover={{
                textDecoration: 'none',
              }}
              rel="noopener noreferrer"
              href="https://app.jescoasbestos.com.au"
              target="_blank"
            >
              <Button
                variant="outline"
                size="sm"
                variantColor="blue"
                bg="transparent"
              >
                Member portal
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
