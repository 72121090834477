import React from 'react';
import Headroom from 'react-headroom';
import { Global, css } from '@emotion/core';
import { Box } from '@chakra-ui/core';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { MobileNavbar } from './MobileNavbar';

export const GlobalStyles = css`
  html,
  body,
  #root {
    height: 100%;
    min-width: 350px;
    width: 100%;
    background-color: #0f1112;
  }

  table {
    width: 100%;
  }

  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 5 !important;
  }
`;

const Layout = ({ children }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Global styles={GlobalStyles} />

      <Box width="100%" height="100%">
        <MobileNavbar isOpen={isOpen} setIsOpen={setIsOpen} />

        <Headroom>
          <Navbar
            isSidebarOpen={isOpen}
            onToggleSidebar={() => setIsOpen(!isOpen)}
          />
        </Headroom>
        {children}
      </Box>

      <Footer />
    </>
  );
};

export default Layout;
