import React from 'react';
import {
  Stack,
  Button,
  Link,
  Flex,
  FlexProps,
  Image,
  Text,
} from '@chakra-ui/core';
import logo from '../images/home-logo-white.png';

export const Footer = (props: FlexProps) => {
  return (
    <Flex
      as="nav"
      justify="center"
      p="1rem"
      width="100%"
      position="relative"
      zIndex={3}
      paddingX="2rem"
      paddingY="3rem"
      bg="#101112"
      color="#101112"
      {...props}
    >
      <Stack width="100%" maxWidth="1200px" spacing="50px" align="center">
        <Button
          variant="solid"
          height="auto"
          variantColor="white"
          onClick={() => window.scrollTo(0, 0)}
        >
          <Image height="70px" src={logo} alt="Footer logo" />
        </Button>

        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          align="center"
          justify="center"
        >
          <Link
            href="#about"
            mb={['30px', '30px', '30px', 'initial']}
            mr={[0, 0, 0, '20px']}
          >
            <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
              About
            </Button>
          </Link>
          <Link
            href="#removal"
            mb={['30px', '30px', '30px', 'initial']}
            mr={[0, 0, 0, '20px']}
          >
            <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
              Removal
            </Button>
          </Link>
          <Link
            href="#assessment"
            mb={['30px', '30px', '30px', 'initial']}
            mr={[0, 0, 0, '20px']}
          >
            <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
              Assessment
            </Button>
          </Link>
          <Link
            href="#credentials"
            mb={['30px', '30px', '30px', 'initial']}
            mr={[0, 0, 0, '20px']}
          >
            <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
              Credentials
            </Button>
          </Link>
          <Link
            href="#location"
            mb={['30px', '30px', '30px', 'initial']}
            mr={[0, 0, 0, '20px']}
          >
            <Button variant="link" size="md" color="#D8D8D8" bg="transparent">
              Location
            </Button>
          </Link>

          <Link
            _hover={{
              textDecoration: 'none',
            }}
            rel="noopener noreferrer"
            href="https://app.jescoasbestos.com.au"
            target="_blank"
          >
            <Button
              variant="outline"
              size="sm"
              variantColor="blue"
              bg="transparent"
            >
              Member portal
            </Button>
          </Link>
        </Flex>
        <Stack
          align="center"
          textAlign={['center', 'center', 'center', 'initial']}
        >
          <Link
            href="https://maps.google.com/maps?q=17 Capital Terrace Queanbeyan NSW, 2620"
            fontWeight={300}
            rel="noopener noreferrer"
            color="#CEE7F2"
            target="_blank"
          >
            17 Capital Terrace Queanbeyan NSW, 2620
          </Link>
          <Text fontWeight={300} color="#CEE7F2">
            ABN: 95607762201
          </Text>
          <Text fontWeight={300} color="#CEE7F2">
            License No: ARA00003
          </Text>
          <Text fontWeight={300} color="#CEE7F2">
            License Class: Class A
          </Text>
          <Link
            color="#CEE7F2"
            fontWeight={400}
            href="mailto: admin@jescoasbestos.com.au"
          >
            admin@jescoasbestos.com.au
          </Link>
        </Stack>
      </Stack>
    </Flex>
  );
};
